/* eslint-disable react/prop-types */
//import React from 'react'
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Newuser from './newuser'
import Userlists from './userlists'
import Newuseredit from './newuseredit'
import UserSenha from './userSenha'

import AuthUser from '../../components/AuthUser';


const UserIndex = ({ match })  => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAcessoAtualizar, setPermissaoAcessoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('UTILIZADORES','ver'));
        setPermissaoAdicionar(verificarPermissao('UTILIZADORES','adicionar'));
        setPermissaoAtualizar(verificarPermissao('UTILIZADORES','atualizar'));
        setPermissaoAcessoAtualizar(verificarPermissao('ACESSO','atualizar'));
        setPermissaoAtivar(verificarPermissao('UTILIZADORES','ativar'));
        setPermissaoRemover(verificarPermissao('UTILIZADORES','remover'));

    },);

    return (
    <Switch>

        <Redirect exact from={`${match.url}/`} render={(props) => <Newuser {...props} permissaoAdicionar={permissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />
        <Route path={`${match.url}/newuser`} render={(props) => <Newuser {...props} permissaoAdicionar={permissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />
        <Route path={`${match.url}/userlists`}  render={(props) => <Userlists {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} permissaoAtualizar={permissaoAtualizar} />} />
        <Route path={`${match.url}/newuseredit/:id`}  render={(props) => <Newuseredit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} permissaoAtivar={permissaoAtivar} />}   />

        {/* Todo utilizador deve poder alterar a sua senha */}
        <Route path={`${match.url}/alterar-senha`} component={UserSenha}  />

    </Switch>
)
}

export default UserIndex