/* eslint-disable no-dupe-keys */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AvocatImage,
  Dash1,
  Dash2,
  Dash3,
  Dash4,
  Dropdown,
  OrangeImage,
  PineappleImage,
  EarpodIcon,
  StawberryImage,
  IphoneIcon,
  SamsungIcon,
  MacbookIcon,
} from "../EntryFile/imagePath";
import Table from "../EntryFile/datatables"
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import RightSideBar from "../components/rightSidebar";
//import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook, faAddressCard, faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft, faAngleRight, faAngleUp, faApple, faArchive, faChartArea, faArrowCircleDown, faArrowCircleLeft, faArrowCircleRight, faArrowCircleUp, faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faAssistiveListeningSystems,
  faAsterisk,
  faAt,
  faAudioDescription,
  faBackward,
  faBalanceScale,
  faBan,
  faBarcode,
  faBars, faBath,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBellSlash,
  faBicycle,
  faBinoculars,
  faBirthdayCake,
  faBlind,
  faBold,
  faBolt,
  faBomb,
  faBook,
  faBookmark,
  faBraille,
  faBriefcase,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBus,
  faCalculator,
  faCalendar,
  faCamera,
  faCameraRetro,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCartArrowDown, faCartPlus, faCertificate, faCheck, faCheckCircle, faChevronCircleLeft, faChevronCircleRight, faChevronCircleUp, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faChild, faCircle, faClipboard, faClone, faCloud, faCode, faCoffee, faCog, faCogs, faColumns, faComment, faCompress, faCopyright, faCreditCard,
  faDesktop,
  faEdit,
  faEject,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeSquare,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faEyeSlash,
  faFastBackward,
  faFastForward,
  faFax,
  faFemale,
  faFighterJet,
  faFile,
  faFire,
  faFireExtinguisher,
  faFlag,
  faFlagCheckered,
  faRoad,
  faRocket,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus, faServer, faShare, faShareAlt, faShareAltSquare, faShareSquare, faShip, faShoppingBag, faShoppingBasket, faShoppingCart, faShower, faSignLanguage, faSignal, faSitemap, faSort, faSortDown, faSquare, faStar, faStarHalf, faStepBackward, faStepForward, faStethoscope, faStickyNote, faStop, faStopCircle, faStreetView, faSubscript, faSuitcase, faSuperscript, faTable, faTag, faTags, faTasks, faTaxi, faTerminal, faTextHeight, faTextWidth, faTh, faThLarge, faThList, faThermometer, faThermometerEmpty, faThermometerFull, faThermometerHalf, faThermometerQuarter, faThermometerThreeQuarters, faThumbsDown, faThumbsUp, faTimes, faTimesCircle, faTint, faToggleOff, faToggleOn, faTrademark, faTrain, faTransgender, faTransgenderAlt, faTrash, faTree, faTrophy, faTty, faTv, faUmbrella, faUnderline, faUndo, faUniversalAccess, faUniversity, faUnlink, faUnlock, faUnlockAlt, faUpload, faUserCircle, faUserMd, faUserPlus, faUserSecret, faUserTimes, faUsers, faVenus, faVenusDouble, faVenusMars, faVolumeDown, faVolumeOff, faVolumeUp, faWheelchair, faWifi, faWindowClose, faWindowMaximize, faWindowMinimize, faWindowRestore, faWrench, faKey, faGlobe, faHouseUser
} from '@fortawesome/free-solid-svg-icons';



/* Para o calendario inicio */

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
//import "../../assets/plugins/fullcalendar/fullcalendar.min.css"
//import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

const options1 = [
  { id: 1, text: "Success", text: "Success" },
  { id: 2, text: "Danger", text: "Danger" },
  { id: 3, text: "Info", text: "Info" },
  { id: 4, text: "Primary", text: "Primary" },
  { id: 5, text: "Warning", text: "Warning" },
  { id: 6, text: "Inverse", text: "Inverse" },
];
const defaultValue = "text"; // Set the default value here


/* Para o calendario fim */

const state = {
  series: [
    {
      name: "Previsão de colheita",
      data: [50, 45, 60, 70, 50, 45, 60, 70],
    },
    {
      name: "Sementes plantadas",
      data: [-21, -54, -45, -35, -21, -54, -45, -35],
    },
  ],
  options: {
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 300,
      stacked: true,

      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 5,
        borderRadiusTop: 5,
      },
    },
    xaxis: {
      categories: [
        " Jan ",
        "feb",
        "march",
        "april",
        "may",
        "june",
        "july",
        "auguest",
      ],
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  },
};

const DashboardBanco = () => {

  const publicUrl = process.env.PUBLIC_URL + '/'


  //inicio calendario

  const [startDate, setDate] = useState(new Date()),
    [showCategory, setshowCategory] = useState(false),
    [showmodel, setshowmodel] = useState(false),
    [showEvents, setshowEvents] = useState(false),
    [show, setshow] = useState(false),
    [iseditdelete, setiseditdelete] = useState(false),
    [addneweventobj, setaddneweventobj] = useState(null),
    [isnewevent, setisnewevent] = useState(false),
    [event_title, setevent_title] = useState(""),
    [category_color, setcategory_color] = useState(""),
    [calenderevent, setcalenderevent] = useState(""),
    [weekendsVisible, setweekendsVisible] = useState(true),
    [currentEvents, setscurrentEvents] = useState([]),
    defaultEvents = [
      {
        title: "Event Name 4",
        start: Date.now() + 148000000,
        className: "bg-purple",
      },
      {
        title: "Colheita",
        start: Date.now(),
        end: Date.now(),
        className: "bg-success",
      },
      {
        title: "Plantio",
        start: Date.now() + 168000000,
        className: "bg-info",
      },
      {
        title: "Colheita",
        start: Date.now() + 338000000,
        className: "bg-primary",
      },
    ];
  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("width-100"));
  }, []);

  const handleChange = (date) => {
    setDate(date);
  };
  const addEvent = () => {
    setshowEvents(true);
  };
  const categoryHandler = () => {
    setshowCategory(true);
  };

  const handleClose = () => {
    setisnewevent(false);
    setiseditdelete(false);
    setshow(false);
    setshowCategory(false);
    setshowEvents(false);
    setshowmodel(false);
  };

  const handleEventClick = (clickInfo) => {
    setiseditdelete(false);
    setevent_title(clickInfo.event.title);
    setcalenderevent(clickInfo.event);
  };

  const handleDateSelect = (selectInfo) => {
    setisnewevent(true);
    setaddneweventobj(selectInfo);
  };
  const addnewevent = () => {
    let calendarApi = addneweventobj.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (event_title) {
      calendarApi.addEvent({
        id: 10,
        title: event_title,
        className: category_color,
        start: addneweventobj.startStr,
        end: addneweventobj.endStr,
        allDay: addneweventobj.allDay,
      });
    }
    setisnewevent(false);
  };

  const onupdateModalClose = () => {
    setiseditdelete(false);
    setevent_title("");
  };
  const oncreateeventModalClose = () => {
    setevent_title("");
    setisnewevent(false);
  };
  const removeevent = () => {
    calenderevent.remove();
    setiseditdelete(false);
  };
  const clickupdateevent = () => {
    const newArray = defaultEvents;
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].id === parseInt(calenderevent.id)) {
        newArray[i].title = event_title;
      }
    }
    defaultEvents = newArray;
    setiseditdelete(false);
  };

  const handleClick = () => {
    setshow(true);
  };
  // console.log("showmodel", showmodel);


  //fim calendario

  const [expiredData] = useState([
    {
      id: 1,
      code: "IT001",
      image: OrangeImage,
      productName: "Mão de Obra",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "65 000",
    },
    {
      id: 2,
      code: "IT002",
      image: PineappleImage,
      productName: "Manutenção",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "45 000",
    },
    {
      id: 3,
      code: "IT003",
      image: StawberryImage,
      productName: "Insumos",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "40 000",
    },
    {
      id: 4,
      code: "IT004",
      image: AvocatImage,
      productName: "Equipamentos",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "300 000",
    },
  ]);

  const [recentData] = useState([
    { image: "Trigo (Triticum aestivum).png", date: '02-12-2023', products: "Trigo", unit: "Kg 120", price: "Kz 891.2" },
    { image: "Arroz-(Oryza-sativa).png", date: '04-01-2024', products: "Arroz", unit: "Kg 230", price: "Kz 91.2" },
    { image: "Milho-(Zea-mays).png", date: '24-12-2024', products: "Milho", unit: "Kg 65", price: "Kz 561.2" },
    { image: "Soja-(Glycine-max).png", date: '02-02-2024', products: "Soja", unit: "Kg 80", price: "Kz 1009.2" },
  ]);

  const expiredProductColumns = [
    {
      title: "SNo",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Custo",
      dataIndex: "code",
      render: (text, record) => (
        <Link to="#" style={{ fontSize: "14px" }}>
          {text}
        </Link>
      ),
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: "Descrição",
      dataIndex: "productName",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.productName}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Categoria",
      dataIndex: "categoryName",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },
    {
      title: "Valor Kz",
      dataIndex: "expiryDate",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.expiryDate.length - b.expiryDate.length,
    },
  ];

  const recentDataColumns = [
    {
      title: "Produtos",
      dataIndex: "products",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={publicUrl + "assets/images/sementes/" + record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.products}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.products.length - b.products.length,
      width: "250px",
    },
    {
      title: "Data",
      dataIndex: "date",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Quantidade",
      dataIndex: "unit",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Previsão de vendas",
      dataIndex: "price",
      render: (text, record) => <div style={{ fontSize: "12px" }}>{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },
  ];

  const state7 = {
    series: [44000000, 55000000, 41000000, 17000000, 15000000],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };


  //aqui inicia

  const state10 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Divisão 1", "Divisão 2", "Divisão 3", "Divisão 4", "Divisão 5"],
      },
    },
    series: [
      {
        name: "Clubes ",
        data: [10, 30, 40, 50, 60],
      },
    ],
  };
  const state11 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Herbicidas", "Inseticidas", "Fungicidas", "Nematicidas", "Acaricidas", "Rodenticidas"],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60],
        color: "#5C42C4",
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  const state12 = {
    series: [
      {
        name: "Pagamentos em dia",
        data: [34, 40, 37, 43, 40, 27, 54],
      },
      {
        name: "Pagamentos em atraso",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "Pagamentos em risco",
        data: [8, 36, 20, 10, 30, 28, 32],
      },
    ],
    options: {
      chart: {
        height: 200,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  const state15 = {
    series: [
      {
        data: [400, 430, 448, 470],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Area Norte",
          "Area Sul",
          "Area Este",
          "Area Oeste"
        ],
      },
    },
  };

  const state18 = {
    series: [44, 55, 67],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 249;
              },
            },
          },
        },
      },
      labels: ["Pesticidas", "Sementes", "Fertilizantes"],
    },
  };

  const state13 = {
    series: [
      {
        name: "Despesa",
        data: [12, 44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
      },
      {
        name: "Entradas",
        data: [58, 76, 85, 101, 98, 87, 105, 91, 114, 94, 80, 120],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
      },
      yaxis: {
        title: {
          text: "Kz (kwanzas)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };


  const state6 = {
    series: [44000000, 55000000, 41000000, 28000000, 36000000],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: ["Divisão 1", "Divisão 2", "Divisão 3", "Divisão 4", "Divisão 5"], // Adicione os rótulos desejados aqui
    },
  };


  //aqui termina


  return (
    <>
      <div className="page-wrapper">
        <Helmet>
          <title>AgroBusiness System</title>
          <meta name="description" content="Dashboard page" />
        </Helmet>
        <div className="content">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash2} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={100000000} />
                    </span>
                  </h5>
                  <h6>Credito total concedido</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash1">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash1} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>

                    <span className="counters">
                      <CountUp end={50000} />
                    </span>
                  </h5>
                  <h6>Agricultores atendidos</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash2">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash2} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={230000000.5} />
                    </span>
                  </h5>
                  <h6>Produção total agricultores</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash3">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash4} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={10} />
                    </span>
                  </h5>
                  <h6>Províncias abrangidas</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>5</h4>
                  <h5>DIVISÕES</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="square" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>200</h4>
                  <h5>CLUBES</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="list" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>100.000</h4>
                  <h5>AGRICULTORES</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="user" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>200.000</h4>
                  <h5>BENEFICIADOS</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="users" />
                </div>
              </div>
            </div>
          </div>


          {/* Começou sementes plantas atualemnte */}


          {/* terminou sementes plantadas atualmente */}


          {/* começou grafico de linha */}
          <div className="row">

            {/* Chart */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title">Status do Crédito</h5>
                </div>
                <div className="card-body">
                  <div id="s-line-area" />
                  <Chart
                    options={state12.options}
                    series={state12.series}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}


          </div>
          {/* terminou grafico de linha */}


          {/* Button trigger modal */}
          <div className="row">

            {/* Chart */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                  <div className="card-title">Distribuição do crédito</div>
                </div>
                <div className="card-body">
                  <div className="chartjs-wrapper-demo">
                    <Chart
                      options={state6.options}
                      series={state6.series}
                      type="donut"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* /Chart */}


            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">Clubes por divisão</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataview">
                    <Chart
                      options={state10.options}
                      series={state10.series}
                      type="bar"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>


          {/* inicio do grafico de despesas versos entradas */}
          <div className="row">


            {/* Chart */}
            <div className="col-md-12">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title">Custos X Produção</h5>
                </div>
                <div className="card-body">
                  <div id="s-col" />
                  <Chart
                    options={state13.options}
                    series={state13.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}

          </div>
          {/* fim inicio do grafico de despesas versos entradas */}


        {/* novo inicio */}

        <div className="row">
            {/* Chart */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Calendario de produção e colheita</div>
                </div>
                <div className="card-body">



                  {/* inicio calendario */}

                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <h4 className="card-title">Eventos</h4>
                      <div id="calendar-events" className="mb-3">
                        <div className="calendar-events" data-class="bg-info">
                          <FontAwesomeIcon icon={faBraille} data-bs-toggle="tooltip" title="fa fa-globe" /> Plantio
                        </div>
                        <div className="calendar-events" data-class="bg-success">
                          <FontAwesomeIcon icon={faBraille} data-bs-toggle="tooltip" title="fa fa-globe" /> Colheita
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-9 col-md-8">
                      <div className="card bg-white">
                        <div className="card-body">
                          <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            initialView="dayGridMonth"
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={weekendsVisible}
                            initialEvents={defaultEvents} // alternatively, use the `events` setting to fetch from a feed
                            select={handleDateSelect}
                            eventClick={(clickInfo) => handleEventClick(clickInfo)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* fim calendario */}


                </div>
              </div>
            </div>
            {/* /Chart */}

          </div>

          {/* novo fim */}

        </div>
      </div>
      <RightSideBar />
    </>
  );
};

export default DashboardBanco;
