/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { LoginImage, Logo, MailIcon } from '../EntryFile/imagePath'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import AuthUser from '../components/AuthUser';
import Spinner from '../components/Spinner'; // Import the Spinner component

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignInPage = (props) => {

    const { http, setToken } = AuthUser();
    const [eye, seteye] = useState(true);

    const onEyeClick = () => {
        seteye(!eye)
    }

    useEffect(() => {
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', true);
            window.location.reload();
        }
    }, []);

    /*start model custom login code*/

    const [email, setEmail] = useState('');
    const [permissaoVer, setPermissaoVer] = useState(9);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    const submitForm = async () => {
        try {
            setLoading(true);

            const res = await http.post('/login', { email: email, password: password });

            setToken(res.data.user, res.data.permissoes, res.data.access_token, res.data.empresa, res.data.banca_permissao);

            toast.success('Credências aceite com sucesso!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            setPermissaoVer(res.data.user.nivel_id)

            if (res.data.banca_permissao)
                props.history.push('/dream-pos/dashboard-banco');
            else
                props.history.push('/dream-pos/dashboard');

        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Credências inválidas, tente novamente!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.error('Algo correu mal, tente novamente mais tarde!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } finally {
            setLoading(false);
        }
    };



    /*end model custom login code*/



    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>Entrar - Agrobusiness</title>
                    <meta name="description" content="SignIn page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                                <form>
                                    <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3>Entrar</h3>
                                        <h4>Por favor acesse a sua conta</h4>
                                    </div>
                                    <div className="form-login">
                                        <label>Email</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"

                                                className=""
                                                placeholder="Digite o e-mail"
                                                defaultValue="dionejava2009@gmail.com"

                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback"></div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Senha</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                className=""
                                                placeholder="Digite a sua senha"

                                                defaultValue={12345678}

                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            <span onClick={onEyeClick} className={`fa toggle-password ${eye ? "fa-eye-slash" : "fa-eye"} `} />
                                            <div className="invalid-feedback"></div>

                                        </div>

                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <Link to="/forgetPassword" className="hover-a">
                                                    Esqueceu-se da senha?
                                                </Link>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button className="btn btn-login" type="button" onClick={submitForm} >
                                            {loading ? <Spinner /> : 'Entrar'}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>


            <ToastContainer />

        </>
    )
}

export default SignInPage;