/* eslint-disable no-unused-vars */
import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AvocatImage,
  Dash1,
  Dash2,
  Dash3,
  Dash4,
  Dropdown,
  OrangeImage,
  PineappleImage,
  EarpodIcon,
  StawberryImage,
  IphoneIcon,
  SamsungIcon,
  MacbookIcon,
} from "../EntryFile/imagePath";
import Table from "../EntryFile/datatables"
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import RightSideBar from "../components/rightSidebar";
//import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";


const state = {
  series: [
    {
      name: "Previsão de colheita",
      data: [50, 45, 60, 70, 50, 45, 60, 70],
    },
    {
      name: "Sementes plantadas",
      data: [-21, -54, -45, -35, -21, -54, -45, -35],
    },
  ],
  options: {
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 300,
      stacked: true,

      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 5,
        borderRadiusTop: 5,
      },
    },
    xaxis: {
      categories: [
        " Jan ",
        "feb",
        "march",
        "april",
        "may",
        "june",
        "july",
        "auguest",
      ],
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  },
};

const Dashboard = () => {

  const publicUrl = process.env.PUBLIC_URL + '/'

  const [expiredData] = useState([
    {
      id: 1,
      code: "IT001",
      image: 'custos (4).jpeg',
      productName: "Mão de Obra",
      brandName: "N/D",
      categoryName: "RH",
      expiryDate: "65 000",
    },
    {
      id: 2,
      code: "IT002",
      image: 'custos (3).jpeg',
      productName: "Manutenção",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "45 000",
    },
    {
      id: 3,
      code: "IT003",
      image: 'custos (2).jpeg',
      productName: "Insumos",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "40 000",
    },
    {
      id: 4,
      code: "IT004",
      image: 'custos (1).jpeg',
      productName: "Equipamentos",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "300 000",
    },
  ]);

  const [recentData] = useState([
    { image: "Trigo (Triticum aestivum).png", date: '02-12-2023', products: "Trigo", unit: "Kg 120", price: "Kz 891.2" },
    { image: "Arroz-(Oryza-sativa).png", date: '04-01-2024', products: "Arroz", unit: "Kg 230", price: "Kz 91.2" },
    { image: "Milho-(Zea-mays).png", date: '24-12-2024', products: "Milho", unit: "Kg 65", price: "Kz 561.2" },
    { image: "Soja-(Glycine-max).png", date: '02-02-2024', products: "Soja", unit: "Kg 80", price: "Kz 1009.2" },
  ]);

  const expiredProductColumns = [
    {
      title: "SNo",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Custo",
      dataIndex: "code",
      render: (text, record) => (
        <Link to="#" style={{ fontSize: "14px" }}>
          {text}
        </Link>
      ),
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: "Descrição",
      dataIndex: "productName",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
          <img alt="" src={publicUrl + "assets/images/" + record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.productName}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },   
    {
      title: "Categoria",
      dataIndex: "categoryName",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },
    {
      title: "Valor Kz",
      dataIndex: "expiryDate",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.expiryDate.length - b.expiryDate.length,
    },
  ];

  const recentDataColumns = [
    {
      title: "Produtos",
      dataIndex: "products",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={publicUrl + "assets/images/sementes/" + record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.products}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.products.length - b.products.length,
      width: "250px",
    },
    {
      title: "Data",
      dataIndex: "date",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Quantidade",
      dataIndex: "unit",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Previsão de vendas",
      dataIndex: "price",
      render: (text, record) => <div style={{ fontSize: "12px" }}>{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },
  ];

  const state7 = {
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };


  //aqui inicia

  const state10 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Foliar", "Líquidos", "Liberação Controlada", "Orgânicos", "Micronutrientes", "Secundários", "Potássicos", "Fosfatados", "Nitrogenados"],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91, 36],
      },
    ],
  };
  const state11 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Herbicidas", "Inseticidas", "Fungicidas", "Nematicidas", "Acaricidas", "Rodenticidas"],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60],
        color: "#5C42C4",
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  const state12 = {
    series: [
      {
        name: "series1",
        data: [34, 12, 37, 43, 21, 10, 23],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  const state15 = {
    series: [
      {
        data: [400, 430, 448, 470],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Area Norte",
          "Area Sul",
          "Area Este",
          "Area Oeste"
        ],
      },
    },
  };

  const state18 = {
    series: [44, 55, 67],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 249;
              },
            },
          },
        },
      },
      labels: ["Pesticidas", "Sementes", "Fertilizantes"],
    },
  };

  const state13 = {
    series: [
      {
        name: "Despesa",
        data: [12, 44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
      },
      {
        name: "Entradas",
        data: [58, 76, 85, 101, 98, 87, 105, 91, 114, 94, 80, 120],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
      },
      yaxis: {
        title: {
          text: "Kz (kwanzas)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };


  const state6 = {
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: ["Trigo", "Arroz", "Milho", "Soja", "Algodão"], // Adicione os rótulos desejados aqui
    },
  };


  //aqui termina


  return (
    <>
      <div className="page-wrapper">
        <Helmet>
          <title>AgroBusiness System</title>
          <meta name="description" content="Dashboard page" />
        </Helmet>
        <div className="content">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash1} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={307144} />
                    </span>
                  </h5>
                  <h6>( Por pagar ) Compras</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash1">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash2} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={4385} />
                    </span>
                  </h5>
                  <h6>( Por receber ) Vendas</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash2">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash3} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={385656.5} />
                    </span>
                  </h5>
                  <h6>Total valor recebido em vendas</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="dash-widget dash3">
                <div className="dash-widgetimg">
                  <span>
                    <img src={Dash4} alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    Kz &nbsp;
                    <span className="counters">
                      <CountUp end={40000} />
                    </span>
                  </h5>
                  <h6>Total valor pago em compras</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>100</h4>
                  <h5>Clientes</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="user" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>45</h4>
                  <h5>Fornecedores</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="user-check" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>100</h4>
                  <h5>Faturas de compras</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="file-text" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>105</h4>
                  <h5>Faturas de vendas</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="file" />
                </div>
              </div>
            </div>
          </div>


          {/* Começou sementes plantas atualemnte */}


          {/* terminou sementes plantadas atualmente */}



          {/* Button trigger modal */}
          <div className="row">

            {/* <div className="col-lg-7 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Plantios recentes</h5>
                  <div className="graph-sets">
                    <div className="dropdown">
                      <button
                        className="btn btn-white btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        2023
                        <img src={Dropdown} alt="img" className="ms-2" />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <Link to="#" className="dropdown-item">
                            2023
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item">
                            2022
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item">
                            2021
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div> */}


            {/* Chart */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                  <div className="card-title">Sementes plantadas atualmente</div>
                </div>
                <div className="card-body">
                  <div className="chartjs-wrapper-demo">
                    <Chart
                      options={state6.options}
                      series={state6.series}
                      type="donut"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* /Chart */}


            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">Previsão de colheita</h4>
                  <div className="dropdown dropdown-action profile-action">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="dropset"
                    >
                      {/* <FontAwesomeIcon icon={""} /> */}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <Link
                          to="/dream-pos/product/productlist-product"
                          className="dropdown-item"
                        >
                          Colheitas
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/dream-pos/product/addproduct-product"
                          className="dropdown-item"
                        >
                          Product Add
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataview">
                    <Table
                      className="bg-denger"
                      columns={recentDataColumns}
                      dataSource={recentData}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>


          {/* começou grafico de linha */}
          <div className="row">

            {/* Chart */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title">Histórico de plantio ao longo dos anos</h5>
                </div>
                <div className="card-body">
                  <div id="s-line-area" />
                  <Chart
                    options={state12.options}
                    series={state12.series}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}


          </div>
          {/* terminou grafico de linha */}



          {/* novo inicio */}

          <div className="row">
            {/* Chart */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Aplicação de fertilizantes</div>
                </div>
                <div className="card-body">
                  <Chart
                    options={state10.options}
                    series={state10.series}
                    type="bar"
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}
            {/* Chart */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Aplicação de pesticidas </div>
                </div>
                <div className="card-body">
                  <Chart
                    options={state11.options}
                    series={state11.series}
                    type="bar"
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}
          </div>

          {/* novo fim */}




          {/* Inicio Irrigação e consumo de insumos   */}
          <div className="row">

            {/* Chart */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Consumo de Insumos por area</h5>
                </div>
                <div className="card-body">
                  <div id="radial-chart" />
                  <Chart
                    options={state18.options}
                    series={state18.series}
                    type="radialBar"
                    height={318}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}

            {/* Chart */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Irrigação por áreas</h5>
                </div>
                <div className="card-body">
                  <div id="s-bar" />
                  <Chart
                    options={state15.options}
                    series={state15.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}


          </div>
          {/* Fim Irrigação e consumo de insumos   */}


          {/* inicio do grafico de despesas versos entradas */}
          <div className="row">


            {/* Chart */}
            <div className="col-md-12">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title">Despesas X Entradas</h5>
                </div>
                <div className="card-body">
                  <div id="s-col" />
                  <Chart
                    options={state13.options}
                    series={state13.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}

          </div>
          {/* fim inicio do grafico de despesas versos entradas */}


          <div className="card mb-0">
            <div className="card-body">
              <h4 className="card-title">Tabela de custos operacionais</h4>
              <div className="table-responsive dataview">
                <Table
                  columns={expiredProductColumns}
                  dataSource={expiredData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSideBar />
    </>
  );
};

export default Dashboard;
