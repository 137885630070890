import React from 'react'
import { LoginImage, Logo, MailIcon, } from '../EntryFile/imagePath'
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'



const ForgotPassword = () => {

    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>Esqueceu a senha - AgroBusiness</title>
                    <meta name="description" content="ForgetPassword page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset ">
                                <div className="login-logo">
                                    <img src={Logo} alt="img" />
                                </div>
                                <div className="login-userheading">
                                    <h3>Esqueceu-se da senha?</h3>
                                    <h4>
                                        Não te preocupes, é normal. por favor digite o e-mail <br />
                                        associado a sua conta.
                                    </h4>
                                </div>
                                <div className="form-login">
                                    <label>Email</label>
                                    <div className="form-addons">
                                        <input
                                            type="text"
                                            placeholder="Digite o seu e-mail"
                                        />
                                        <img src={MailIcon} alt="img" />
                                    </div>
                                </div>
                                <div className="form-login">
                                    <button type='submit' className="btn btn-login">
                                        Entrar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;